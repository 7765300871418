export const ROUTE_PARAMS_TOKENS = {
  BADGE: 'badgeId',
  BRAND: 'brandId',
  DISCOUNT: 'discount',
  EXHIBITION: 'exhibitionId',
  NOTIFICATION: 'notificationId',
  PROJECT: 'projectId',
  PROMOCODE: 'promocodeId',
  STAGE: 'stageId',
  STAND: 'standId',
  TICKET: 'ticketId',
} as const;
